import React from 'react';
import { withPrefix } from 'gatsby';
import Layout from '../../components/layouts/project';

const IndexPage = () => (
  <Layout
    title="Typoo Defender"
    description="Learn to type slower in this game written for Jamyard, a two-day game jam themed after misspellings."
  >
    <div>
      <h1 className="my-12 mx-8 text-center">Typoo Defender</h1>
      <div className="demo py-12 overflow-x-auto bg-gray-900">
        <iframe
          className="typoo-defender border-0 overflow-hidden block"
          src={withPrefix('/TypooDefender/dist/index.html')}
          allow="autoplay"
        />
      </div>
      <div className="details m-auto py-12 px-8 max-w-2xl">
        <h2>Instructions</h2>
        <p>
          Learn to type slower as you screw up your knowledge of the English
          language!
        </p>

        <h2>Details</h2>
        <p>
          <em>Typoo Defender</em> was a game built for Jamyard, a two-day game
          jam hosted at Vidyard in February 2017. The theme was
          &ldquo;misspellings&rdquo; (based off of an inside joke between
          co-workers). I opted for something typing-related in the same vein as
          TypeRacer &mdash; with the opposite goal.
        </p>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
